import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import ScrollToTop from "./utils/ScrollToTop";
import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";

import { Assets } from "./pages/Assets";
// import { PitchDeck } from "./pages/PitchDeck";
import { DynamicPitch } from "./pages/DynamicPitch";
import { Discover } from "./pages/Discover";
import { Resources } from "./pages/Resources";
import { Pitch } from "./components/Resources/pitch";
import { Raise } from "./components/Resources/raise";
import { Agreements } from "./components/Resources/agreements";
import { Dashboard } from "./components/Resources/dashboard";
import { Experts } from "./components/Resources/experts";
import { Accelerators } from "./components/Resources/accelerators";
import { Discord } from "./components/Resources/discord";

function App() {
  return (
    <Router>
      <NavBar />
      {/* <ScrollToTop /> */}
      <Routes>
        {/* <Route exact path="/" element={<PitchDeck />} /> */}
        <Route exact path="/*" element={<NotFound />} />
        <Route exact path="/assets" element={<Assets />} />
        <Route exact path="/" element={<Discover />} />
        <Route exact path="/resources" element={<Resources />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="pitch" element={<Pitch />} />
          <Route path="raise" element={<Raise />} />
          <Route path="agreements" element={<Agreements />} />
          <Route path="experts" element={<Experts />} />
          <Route path="accelerators" element={<Accelerators />} />
          <Route path="discord" element={<Discord />} />
        </Route>
        <Route path="in/:pitchUrl" element={<DynamicPitch />} />
      </Routes>
    </Router>
  );
}

export default App;
