import { Button, Card, Col, Divider, Row } from "antd";

export const Agreements = () => {
  const agreementData = [
    {
      catagory: "Hire Someone",
      items: [
        {
          title: "Advisor Agreement",
          description:
            "Add an advisor to your team. choose from different advisor types. define their deliverables. set remuneration in cash and/or equity. includes non-compete provisions.",
        },
        {
          title: "Consultancy Agreement",
          description:
            "Hire a consultant or contractor. define the project and deliverables. set payment in cash and/or equity. includes nda provisions. the consultant can be a person or a company.",
        },
        {
          title: "NED Agreement",
          description:
            "Hire a non-executive director. set their role, duties and duration of their engagement. set remuneration in cash and/or equity. includes non-compete provisions.",
        },
        {
          title: "Zero Hour Contract",
          description:
            "Sometimes you can't predict your staffing needs, which is where zero hour contracts are useful. the working relationship begins when the person starts their shift and ends when they end their shift.",
        },
        {
          title: "Intern Agreement",
          description:
            "hire an intern. specify their duties. add payment details (interns need to be paid the minimum wage). includes mentoring, reporting & other intern hiring requirements.",
        },
      ],
    },
    {
      catagory: "Protect your IP",
      items: [
        {
          title: "Non-Disclosure Agreement",
          description:
            "Our mutual nda allows you and another party to share confidential information. if the other party is a company, create the nda in the name of the person who'll be signing on their behalf, then add the company details in the nda questions.",
        },
        {
          title: "IP Assignment",
          description:
            "Everyone who's contributed to your product or idea should sign an ip assignment. investors will check this as part of their due diligence. ip assignment provisions are also included in our hiring agreements.",
        },
      ],
    },
    {
      catagory: "Get Investment Ready",
      items: [
        {
          title: "Everyday Investor Certificate",
          description:
            "Friends, family and angel investors are likely to be everyday investors (also referred to by the fca as restricted investors), to be safe you can get them to sign this letter confirming that",
        },
        {
          title: "High Net Worth Investor Certificate",
          description:
            "For larger investments, you may want to have your investors sign either a high net worth or a sophisticated investor certificate, ideally at the time you send them your pitch deck.",
        },
        {
          title: "Sophisticated Investor Certificate",
          description:
            "For larger investments, you may want to have your investors sign either a sophisticated investor or a high net worth certificate, ideally at the time you send them your pitch deck.",
        },
        {
          title: "Investor Disclaimer",
          description:
            "An investor disclaimer protects you by having the investor declare they're a sophisticated or high net worth investor. you can include it in the materials you send to your investors, as needed.",
        },
        {
          title: "Due Diligence Request",
          description:
            "Get investment ready by starting on your due diligence checklist now, so you're ready with the data room documents your investors will be asking for at your next funding round.",
        },
      ],
    },
    {
      catagory: "Commercial Agreements",
      items: [
        {
          title: "Warrant Agreement",
          description:
            "A warrant gives someone a right to buy shares at a future date, at a price that you agree now. a typical use would be to give someone an agreed % equity in your next round, in return for their providing services now.",
        },
        {
          title: "Affiliate Agreement",
          description:
            "Want to incentivise partners to refer customers to you? Our affiliate agreement lets you specify which items earn a referral fee, for how long, when commission will be paid, and more.",
        },
      ],
    },
  ];

  const listAgreementData = agreementData.map((data) => {
    const listItems = data.items.map((data) => {
      return (
        <Col xs={24} sm={24} md={8}>
          <Card title={data.title} actions={[<Button>Create</Button>]}>
            <p>{data.description}</p>
          </Card>
        </Col>
      );
    });
    return (
      <Row gutter={[18, 18]}>
        <Divider orientation="left">{data.catagory}</Divider>
        {listItems}
      </Row>
    );
  });

  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>Agreements</h1>
        </Col>
      </Row>
      {listAgreementData}
    </div>
  );
};
