import { Button, Col, Result, Row } from "antd";

export const Discord = () => {
  return (
    <div style={{ margin: "15px" }}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <Result
            icon={
              <img
                alt="discord logo"
                width={"400px"}
                style={{ maxHeight: "400px" }}
                src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"
              />
            }
            title="Join Our Discord Community and Connect with other Founders"
            extra={<Button type="primary">Click to Join</Button>}
          />
        </Col>
      </Row>
    </div>
  );
};
