import { Col, Row } from "antd";

export const Accelerators = () => {
  return (
    <div style={{ margin: "15px" }}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>accelerators</h1>
        </Col>
        <ul>
          <li>Hatch</li>
          <li>John Keells X</li>
          <li>GLX</li>
        </ul>
      </Row>
    </div>
  );
};
