import { Button, Col, Result, Row } from "antd";

export const Raise = () => {
  return (
    <div className="main" style={{ margin: "15px", textAlign: "center" }}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>Raise</h1>
        </Col>
        <Col xs={24} sm={24} md={16}>
          <Result
            icon={
              <img
                alt="cartoon of a man on light bulb"
                width={"100%"}
                style={{ maxHeight: "400px" }}
                src="https://www.volta.ventures/wp-content/uploads/2022/01/20943414.jpg"
              />
            }
            title="Get Ready to Raise Your First Round"
          />
        </Col>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "left" }}>
          <ul>
            <li>Brand Deck</li>
            <li>Company Profile</li>
            <li>One Pager</li>
            <li>Investor Pitch Deck</li>
            <li>Financial Model</li>
            <li>Investor Finder</li>
          </ul>
        </Col>
        <Col span={24}>
          <Button>Get Started</Button>
        </Col>
      </Row>
    </div>
  );
};
