import { Button, FloatButton, Layout, Menu } from "antd";
import { Link, Outlet } from "react-router-dom";

import {
  MenuUnfoldOutlined,
  CodeSandboxOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useState } from "react";

export const Resources = () => {
  const { Content, Sider } = Layout;

  const [showFloat, setShowFloat] = useState(false);

  const resourcePageData = [
    {
      key: 1,
      title: "Dashboard",
      url: "/resources/dashboard",
      icon: <HomeOutlined />,
    },
    {
      key: 2,
      title: "Pitch",
      url: "/resources/pitch",
      icon: <CodeSandboxOutlined />,
    },
    {
      key: 3,
      title: "Raise",
      url: "/resources/raise",
      icon: <HomeOutlined />,
    },
    {
      key: 4,
      title: "Agreements",
      url: "/resources/agreements",
      icon: <HomeOutlined />,
    },
    {
      key: 5,
      title: "Mentors & Coaches",
      url: "/resources/experts",
      icon: <HomeOutlined />,
    },
    {
      key: 6,
      title: "Accelerators",
      url: "/resources/accelerators",
      icon: <HomeOutlined />,
    },
    {
      key: 7,
      title: (
        <Button style={{ background: "black", color: "white" }}>
          {"Discord"}
        </Button>
      ),
      url: "/resources/discord",
      icon: <HomeOutlined />,
    },
  ];

  const listResourcePageData = resourcePageData.map((data) => {
    return {
      key: data.key,
      icon: "",
      label: (
        <Link
          style={{ textDecoration: "none", color: "grey", height: "20px" }}
          to={data.url}
        >
          {data.title}
        </Link>
      ),
    };
  });

  const FloatIcon = () => {
    const listIcons = resourcePageData.map((data) => {
      return (
        <Link
          style={{ textDecoration: "none", color: "grey", height: "20px" }}
          to={data.url}
        >
          <FloatButton
            icon={data.icon}
            tooltip={<div>{data.title}</div>}
            style={{ margin: "5px 0px" }}
          />
        </Link>
      );
    });
    return (
      <FloatButton.Group
        icon={<MenuUnfoldOutlined />}
        type="primary"
        trigger="click"
      >
        {listIcons}
      </FloatButton.Group>
    );
  };

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <div
        style={{
          maxWidth: "1400px",
          width: "100%",
        }}
      >
        <Layout>
          <Sider
            style={{ backgroundColor: "white", minHeight: "500px" }}
            breakpoint="md"
            collapsedWidth="0"
            trigger={null}
            zeroWidthTriggerStyle={<FloatButton />}
            onCollapse={() => {
              setShowFloat(!showFloat);
            }}
          >
            <Menu
              theme="white"
              mode="inline"
              items={listResourcePageData}
              style={{ height: "20px" }}
              defaultSelectedKeys={["1"]}
            />
          </Sider>
          <Layout>
            <Content
              style={{ height: "90vh", overflow: "scroll", width: "100%" }}
              hasSider
            >
              <Outlet />
              {showFloat && <FloatIcon />}
            </Content>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};
