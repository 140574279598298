import { Avatar, Button, Card, Col, Divider, Row, Tag } from "antd";

export const Experts = () => {
  const agreementData = [
    {
      catagory: "Pitch Coaches",
      items: [
        {
          name: "Webinar",
          blurb: "",
          avatar:
            "https://media.licdn.com/dms/image/C4D03AQGQgyGQIQW5Hw/profile-displayphoto-shrink_800_800/0/1663690442529?e=1701302400&v=beta&t=PraOepa6kR_BrievuGGRvuLZJIQN8wIBuVxnrlTw2jQ",
          description:
            "Join one of our bi-weekly interactive webinars where our experts provide valuable resources in financial modeling, presentation design, and pitch deck writing so that you get your startup to the next level.",
          cost: "FREE/30mins",
          tags: [""],
          allToAction: "Schedule A Call",
        },
      ],
    },
    {
      catagory: "Legal",
      items: [
        {
          name: "Allan Boruchowicz",
          blurb: "Managing Partner at Carao Ventures",
          avatar:
            "https://slidebean.s3.amazonaws.com/experts/allan-boruchowicz.jpg",
          description:
            "Join one of our bi-weekly interactive webinars where our experts provide valuable resources in financial modeling, presentation design, and pitch deck writing so that you get your startup to the next level.",
          tags: ["pitch decks"],
          cost: "FREE/30mins",
          allToAction: "Schedule A Call",
        },
      ],
    },
    {
      catagory: "Corporate Services",
      items: [],
    },
    {
      catagory: "Fund Raising",
      items: [],
    },
  ];

  const listAgreementData = agreementData.map((data) => {
    const listItems = data.items.map((data) => {
      const listTags = data.tags.map((data) => {
        return <Tag>{data}</Tag>;
      });
      return (
        <Col xs={24} sm={24} md={8}>
          <Card
            title={data.title}
            actions={[
              <Button>{data.cost}</Button>,
              <Button>{data.allToAction}</Button>,
            ]}
          >
            <Row gutter={[18, 18]}>
              <Col span={18}>
                <p style={{ fontSize: "18px" }}>
                  <b>{data.name}</b>
                </p>
                <p>{data.blurb}</p>
              </Col>
              <Col span={6}>
                <Avatar
                  style={{ width: "100%", height: "auto" }}
                  src={data.avatar}
                />
              </Col>
              <Col span={24}>
                <p>{data.description}</p>
                {listTags}
              </Col>
            </Row>
          </Card>
        </Col>
      );
    });
    return (
      <Row gutter={[18, 18]}>
        <Divider orientation="left">{data.catagory}</Divider>
        {listItems}
      </Row>
    );
  });

  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={[18, 18]}>
        <Col span={24}></Col>
      </Row>
      {listAgreementData}
    </div>
  );
};
