import { Col, Row } from "antd";

export const Dashboard = () => {
  return (
    <div className="main">
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>Dashboard</h1>
        </Col>
      </Row>
    </div>
  );
};
